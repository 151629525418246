#document #parameter{
  max-height: 655px;
  overflow-y: auto;
}
#document #parameter div{
  width: calc(50% - 10px);
  border:1px solid #CCC;
  padding:3px 10px;
  text-align: center;
  margin: 0 5px 5px 5px;
  cursor: pointer;
  font-size:9px;
  line-height: 17px;
  float:left;
}
#document #parameter div.param-title{
  font-weight: bold;
  width: calc(100% - 10px);
  font-size:12px;
}
#document #parameter div.green{
  background: #1dc116;
  color:#FFF;
}
#document #parameter div span{
  font-weight: bold;
}