#discount{
  .margin-bottom{
    margin-bottom: 15px;
  }
  .margin-left{
    margin-left: 40px;
  }
  .proposals-table {
    .desc{
      max-width: 300px;
      word-wrap: break-word;
    }
  }
  .margin-right{
    margin-right: 40px
  }
  .inline-block{
    display: inline-block;
  }
  .vcenter{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}