#logistics-module-content, #logistics-module-header {
  --logistics-pink: #ff94f6;
  --logistics-purple: #90c;
  --logistics-orange: #f94;
  --logistics-gray: #777;
  --logistics-green: #39b32d;
  --logistics-light-blue: #5bb6ff;
  --logistics-blue: #407cb0;
  .single-place-div {
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    height: 20px;
    max-height: 20px;
    margin: 2px;
  }

  .single-place-div:hover:not(.counter-div) {
    border: 2px solid black;
  }

  .taken-place {
    background-color: red;
  }

  .free-place {
    background-color: #67cd00;
  }

  .selected-place {
    background-color: #0000ff;
  }

  .selected-car-taken-place {
    background-color: #0000ff;
  }

  .panel-sticky {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .panel-row-sticky th {
    background-color: white;
    position: sticky;
    top: 36px;
    z-index: 1;
  }

  .panel-pink {
    border-color: var(--logistics-pink);
  }
  .panel-pink>.panel-heading {
    color: #fff;
    background-color: var(--logistics-pink);
    border-color: var(--logistics-pink);
  }
  .panel-pink>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: var(--logistics-pink);
  }
  .panel-pink>.panel-heading .badge {
    color:  var(--logistics-pink);
    background-color:#fff;
  }
  .panel-pink>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: var(--logistics-purple);
  }
  .btn-pink {
    color: #fff;
    background-color: var(--logistics-purple);
    border-color: var(--logistics-purple);
  }
  .panel-purple {
    border-color: var(--logistics-purple);
  }
  .panel-purple>.panel-heading {
    color: #fff;
    background-color: var(--logistics-purple);
    border-color: var(--logistics-purple);
  }
  .panel-purple>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: var(--logistics-purple);
  }
  .panel-purple>.panel-heading .badge {
    color:var(--logistics-purple);
    background-color:#fff;
  }
  .panel-purple>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: var(--logistics-purple);
  }
  .btn-purple {
    color: #fff;
    background-color: var(--logistics-purple);
    border-color: var(--logistics-purple);
  }
  .panel-orange {
    border-color: var(--logistics-orange);
  }
  .panel-orange>.panel-heading {
    color: #fff;
    background-color: var(--logistics-orange);
    border-color: var(--logistics-orange);
  }
  .panel-orange>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: var(--logistics-orange);
  }
  .panel-orange>.panel-heading .badge {
    color:var(--logistics-orange);
    background-color:#fff;
  }
  .panel-orange>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: var(--logistics-orange);
  }
  .btn-orange {
    color: #fff;
    background-color: var(--logistics-orange);
    border-color: var(--logistics-orange);
  }
  .panel-gray {
    border-color: var(--logistics-gray);
  }
  .panel-gray>.panel-heading {
    color: #fff;
    background-color: var(--logistics-gray);
    border-color: var(--logistics-gray);
  }
  .panel-gray>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: var(--logistics-gray);
  }
  .panel-gray>.panel-heading .badge {
    color:var(--logistics-gray);
    background-color:#fff;
  }
  .panel-gray>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: var(--logistics-gray);
  }
  .btn-gray {
    color: #fff;
    background-color: var(--logistics-gray);
    border-color: var(--logistics-gray);
  }
  .panel-light-blue {
    border-color: var(--logistics-light-blue);
  }
  .panel-light-blue>.panel-heading {
    color: #fff;
    background-color: var(--logistics-light-blue);
    border-color: var(--logistics-light-blue);
  }
  .panel-light-blue>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: var(--logistics-light-blue);
  }
  .panel-light-blue>.panel-heading .badge {
    color:var(--logistics-light-blue);
    background-color:#fff;
  }
  .panel-light-blue>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: var(--logistics-light-blue);
  }
  .btn-light-blue {
    color: #fff;
    background-color: var(--logistics-light-blue);
    border-color: var(--logistics-light-blue);
  }
  .panel-blue {
    border-color: var(--logistics-blue);
  }
  .panel-blue>.panel-heading {
    color: #fff;
    background-color: var(--logistics-blue);
    border-color: var(--logistics-blue);
  }
  .panel-blue>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: var(--logistics-blue);
  }
  .panel-blue>.panel-heading .badge {
    color:var(--logistics-blue);
    background-color:#fff;
  }
  .panel-blue>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: var(--logistics-blue);
  }
  .btn-blue {
    color: #fff;
    background-color: var(--logistics-blue);
    border-color: var(--logistics-blue);
  }
  .panel-green {
    border-color: var(--logistics-green);
  }
  .panel-green>.panel-heading {
    color: #fff;
    background-color: var(--logistics-green);
    border-color: var(--logistics-green);
  }
  .panel-green>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: var(--logistics-green);
  }
  .panel-green>.panel-heading .badge {
    color:var(--logistics-green);
    background-color:#fff;
  }
  .panel-green>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: var(--logistics-green);
  }
  .btn-green {
    color: #fff;
    background-color: var(--logistics-green);
    border-color: var(--logistics-green);
  }


  .logistics-buttons-td {
    width: 300px;
  }
  @media (max-width: 1350px) {
    .logistics-buttons-td {
      width: unset;
      max-width: 300px;
    }
  }
  .substitutions_panel_heading_item {
    flex-grow: 1;
  }
  .custom-png-icon {
    width: 11px;
    height: 11px;
  }
}