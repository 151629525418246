@import "bootstrap4";

$btn-orange-color:               #fff !default;
$btn-orange-bg:                  #ff7e00 !default;
$btn-orange-border:              darken(#ff7e00, 5%) !default;
.btn-orange {
	@include button-variant($btn-orange-color, $btn-orange-bg, $btn-orange-border);
}
$btn-girl-color:               #fff !default;
$btn-girl-bg:                  #e617f3 !default;
$btn-girl-border:              darken(#e617f3, 5%) !default;
.btn-girl {
	@include button-variant($btn-girl-color, $btn-girl-bg, $btn-girl-border);
}
$btn-purple-color:               #fff !default;
$btn-purple-bg:                  #9243ac !default;
$btn-purple-border:              #391a44 !default;
.btn-purple {
	@include button-variant($btn-purple-color, $btn-purple-bg, $btn-purple-border);
}
body a { cursor: pointer; }
.btn-static { background-color: white; border: 1px solid lightgrey; cursor: default; }
.btn-static:active {
	-moz-box-shadow: inset 0 0 0px white;
	-webkit-box-shadow: inset 0 0 0px white;
	box-shadow: inset 0 0 0px white;
}
label.error { color: #f33; font-size: 11px; }
label.error2 { color: #f33; font-size: 11px; }
.user-menu { display: block; padding: 3px 20px; clear: both; font-weight: 400;
	line-height: 1.428571429; color: #333; white-space: nowrap; }
.ui-autocomplete{z-index: 99999999 !important;}
.marg-btm{margin-bottom: 10px;}
.marg-right{margin-right: 10px;}
.marg-left{margin-left: 10px;}
.marg-top{margin-top: 25px;}
.marg-top-min{margin-top: 10px;}
.no-marg-btm{margin-bottom: 0px;}
.padding-btm{padding-bottom: 20px;}
.padding-btm-min{padding-bottom: 10px;}
.padding-top{padding-top: 20px;}
.padding-top-min{padding-top: 10px;}
.row div.editable .form-group{height:34px;}
.upper { text-transform:uppercase; }
//Dropzone
.dropzone-hide .dz-preview { display: none; }
//typeahead
.twitter-typeahead, .tt-hint, .tt-input, .tt-menu { width: 100%; }
input.form-control.tt-hint[disabled] { background-color: #eee !important; }
//table
.table-responsive { float: left; }
.table-auto { float: left; width: auto; }
.table.table-auto { width: auto; }
.table.vcenter td { vertical-align: middle; }
.table.table-sm { font-size: 11px; }
.table.table-sm thead tr th { padding: 3px; }
.table.table-sm tbody tr td { padding: 3px; }
.table.table-sm input { font-size: 11px; height: 24px; padding: 3px 6px; }
.table.table-sm textarea { font-size: 11px; padding: 3px 6px; resize: none; }
.table.table-sm select { font-size: 11px; height: 24px; padding: 3px 6px; }
.table.table-sm .bootstrap-select .btn.dropdown-toggle { font-size: 11px; height: 24px; padding: 3px 6px; }
.table.table-xs thead tr th { padding: 1px; }
.table.table-xs tbody tr td { padding: 1px; }
.table.table-notop tr:first-child th { border-top: none; }
.table.table-borderless td, .table.table-borderless th { border: none; }
.table tr.strong td { border-top: 1px solid #999; }
.table.table-vcenter th { vertical-align: middle; }
.table.table-vcenter td { vertical-align: middle; }
.table tr td.bold { font-weight: bold; }
//panels
.panel-menu { cursor: pointer; }
.panel-menu.panel-chosen { background-color: $brand-primary; color: #fff; }
.panel-sm .panel-body { padding: 10px; }
//pagination
.pagination { margin: 0px; }
//hr
hr.sm { margin-top: 4px; margin-bottom: 4px; }
//fa
.fa-cancel { color: #c23; vertical-align: middle; cursor: pointer; }
i.fa.danger { color: $brand-danger; }
i.fa.warning { color: $brand-warning; }
i.fa.success { color: $brand-success; }
//footer
html { position: relative; min-height: 100%; }
body { padding: 0px; margin-bottom: 60px; }
.footer { position: absolute; width: 100%; height: 40px; bottom: 0; background-color: $navbar-inverse-bg; }
.footer p { margin-top: 12px; color: $navbar-inverse-color; }
.footer a { color: $navbar-inverse-color; }
//label
.label.label-big { font-size: 100%; }
.label.label-normal { font-size: 100%; font-weight: normal; }
.label.label-warning2 { background-color: #faf200; color: #000; }
.label.label-danger-light { background-color: #f2dede; color: #000; }
//headers
h3.inverse { background-color: #666; color: #fff; margin: 0px; padding: 8px; border-radius: 4px; font-size: 18px; }
//logo
#logo_domain{margin-top: -12px;}
//newcars
div.gallery-photo { position: relative; height: 120px; text-align: center; margin-bottom: 10px; }
div.gallery-photo img { max-width: 100%; height: auto; max-height: 100%; border-radius: 8px; }

//socket
.power-socket{
	&.power-socket-status {
		float: right;
		font-size: 35px;
		margin-top: -10px;
		color: #ac2925;

		&.power-socket-on {
			color: #3D7700;
		}
	}
}
th.red,td.red{background:#FF0000;}
.fa-red{color:#FF0000;}
th.yellow,td.yellow{background:#EDA31A;}
.fa-yellow{color:#EDA31A;}

.tooltip-inner { white-space:pre-wrap; }
#modal .modal-dialog.modal-xlg { width: 100%; }
@media (min-width: 1360px) { #modal .modal-dialog.modal-xlg { width: 1300px; } }

.rating_show, .rating {
	unicode-bidi: bidi-override;
	direction: rtl;
	text-align: center;
}
.rating:hover span i{
	color: #333333;
}
.rating > span {
	display: inline-block;
	position: relative;
	width: 1.1em;
	cursor: pointer;
}
.rating .gold:before {
	content: "\2605";
	position: absolute;
	left: 0;
	color: gold;
}
.rating > span.gold:hover,
.rating > span:hover,
.rating > span:hover ~ span {
	color: transparent;
}

.rating > span.gold:hover ~ span {
	color: transparent;
}

.rating > span:hover:before,
.rating > span:hover ~ span:before {
	content: "\2605";
	position: absolute;
	left: 0;
	color: gold;
}
.rating_show > span {
	display: inline-block;
	position: relative;
	width: 1.1em;
}
.rating_show span.gold:before {
	content: "\2605";
	position: absolute;
	left: 0;
	color: gold;
}
.rating_white span i{
	color:#CCC;
}
.dropdownmenu-element{
	cursor: pointer;
	padding:3px 10px;
	display: block;
}
.comment-post{
	padding:10px;
	margin-bottom:10px;
}
.comment-post:nth-child(even){
	background: #eeeeee;
}
.table-sorted th[data-sort] { cursor:pointer; }

/** CUSTOM CHECKBOX
<label class="checkmark-container">
	<input type="checkbox">
	<span class="checkmark"></span>
</label>
 */
.checkmark-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.checkmark-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
	border: 1px solid #2196F3;
}
.checkmark-container:hover input ~ .checkmark {
	background-color: #ccc;
}
.checkmark-container input:checked ~ .checkmark {
	background-color: #2196F3;
}
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
.checkmark-container input:checked ~ .checkmark:after {
	display: block;
}
.checkmark-container .checkmark:after {
	left: 9px;
	top: 6px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

input[type=search]::-webkit-search-cancel-button {
	-webkit-appearance: searchfield-cancel-button;
}

.cursor-pointer {
	cursor: pointer;
}
.cursor-default {
	cursor: default;
}
.text-decoration-none {
	text-decoration: none;
}

@import "document";
@import "carrental";
@import "common-route";
@import "service";
@import "crisis";
@import "discount";
@import "monitor-excise";
@import "logistics";
@import "schedule";