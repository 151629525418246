#monitor-excise-list{
  overflow-x: scroll;
  width:100%;

  table{
    width:5000px;
    td{
      background: #a8a8a896;
      &.edit{
        background: #fff;
        input{
          border:none;
          width:100%;
        }
        select{
          width: 100%;
          background: #FFF;
          border: none;
        }
      }
    }
  }
}