#carrental{
  div.schedule-cell {
    float: left;
    background-color: #ddd;
    position: absolute;
    font-size: 90%;
    top: 4px;
    text-align: center;
  }

  div.schedule-cell div.cell-inside {
    background-color: #fff;
    color: #333;
    margin: 1px;
    padding: 4px;
    overflow: hidden;
  }

  div.schedule-cell.red {
    background-color: #c00;
  }

  div.schedule-cell.red div.cell-inside {
    background-color: #e44;
    color: #fff;
  }

  div.schedule-cell.red div.cell-inside:hover {
    background-color: #e22;
  }

  div.schedule-cell.green {
    background: none;
  }

  div.schedule-cell.green div.cell-inside {
    background: none;
  }

  div.schedule-cell.green div.schedule-time {
    background: #5b5;
  }

  .hidden_car div.schedule-cell.green div.schedule-time {
    background: #4fff4f;
  }

  div.schedule-cell.green div.schedule-time.schedule-time-removed {
    background: #FFF;
  }

  .cell-inside-left {
    left: 0;
    top: 0;
  }

  .cell-inside-right {
    right: 0;
    top: 0;
    margin-left: -5px;
  }

  .cell-inside-left, .cell-inside-right {
    width: 5px;
    height: 21px;
    top: 4px;
    cursor: ew-resize;
    z-index: 900;
    position: absolute;
  }

  .notdrag{
    cursor: auto;
  }

  div.cell-object-red {
    z-index: 500;
    background-color: #FF0000;
  }
  div.cell-object-green {
    background-color: #5b5;
  }
  div.cell-object-grey {
    background-color: #CCC;
  }
  div.cell-object-black{
    background-color: #000;
  }

  .cell-object-style {
    position: absolute !important;
    top: 4px;
    height: 21px;
    text-align: center;
    padding-top: 5px;
    overflow: hidden;
    color: #EEE;
  }

  .cell-object-style span {
    padding: 0 2px;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 23px;
    line-height: 23px;
    margin-top: -5px;
  }

  .schedule-date, .schedule-time {
    position: absolute;
    top: 0;
    height: 21px;
  }

  .schedule-time {
    cursor: pointer;
  }

  .schedule-time-removed {
    cursor: default;
    background: #FFF;
  }

  .superTitle {
    display: none;
    position: absolute;
    z-index: 9999;
    background: #000;
    color: #FFF;
    font-size: 10px;
    padding: 2px 5px;
    border-radius: 5px;
  }

  .schedule-cell.green {
    height: 23px;
  }

  .ui-state-hover div {
    background: #fbd850;
  }

  div.schedule-cell div.cell-inside {
    height: 23px;
  }
  div.schedule-cell div.cell-inside.weekend{
    background: #EEEEEE;
  }
  div.schedule-cell div.cell-inside.hollyday{
    background: #ee749c;
  }
  div.schedule-cell div.cell-inside.hover{
    background: #FFCE37;
  }

  .table.vcenter td {
    padding: 2px 0;
  }

  .context_menu {
    position: absolute;
    display: none;
    z-index: 100000;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    background: #FFF;
    padding: 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-clip: padding-box;
  }

  .context_menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .context_menu ul li {
    display: block;
    padding: 3px 10px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857;
    color: #333;
    white-space: nowrap;
    font-size: 11px;
    cursor: pointer;
  }
  .context_menu ul li ul{
    position: absolute;
    right: -92px;
    display: none;
    background: #FFF;
    margin-top: -19px;
    padding: 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .context_menu ul li:hover ul{
    display: block;
  }

  .context_menu ul li:hover {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5;
  }

  .message {
    position: fixed;
    z-index: 9000000;
    text-align: center;
    background: #FF0000;
    color: #FFF;
    width: 100%;
    padding: 10px 20px;
    top: 0;
    left: 0;
    display: none;

    &.success{
      background: #008000;
    }
  }

  .tooltip {
    white-space: pre-line !important;
  }

  .loading-row {
    padding: 0;
    margin: 0;
  }

  .loading-row img {
    height: 14px;
  }

  .carrental-thumb {
    max-width: 30%;
  }
  .name-car{
    width: 100%;
    display: block;
  }
  .title-car{
    max-width:170px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .absolute_row{
    width: calc(100% - 10px);
    position: absolute;
    top:0;
    height:30px;
    left:10px;
    overflow: hidden;
  }
  #scroll{
    overflow-y: scroll;
    /*width: calc(100% - 350px);
    margin-left:350px;*/
    height: 18px;
    float:right;
  }
  #scroll::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 10px;
  }
  #scroll::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
  }
  #scroll div{
    height: 18px;
  }
  .years{
    font-size:20px;
    float: right;
    margin-right: 20px;
  }
  .years i{
    cursor: pointer;
  }
  .group-title{
    font-weight: bold;
    text-align: center;
  }
  .cell-object-agreement:after{
    content:'';
    background: #FFF;
    position: absolute;
    height: 30px;
    width: 30px;
    transform: rotate(45deg);
    right: -15px;
    top: -16px;
  }
  div div.cell-object-status-1{
    background:#ff9e00;
  }
  div div.cell-object-status-2{
    background:#ff9e00;
  }
  div div.cell-object-status-3{
    color:#000;
    background:#feff26;
  }
  div div.cell-object-status-4{
    background: #00ffdd;
  }
  div div.cell-object-status-5{
    background: #23ff00;
  }
  div div.cell-object-status-6{
    background: #962eff;
  }
  div div.cell-object-status-9{
    background: #000;
  }
  div div.cell-object-status-11,div div.cell-object-status-12{
    background: #ff0002;
  }
  div div.cell-object-status-10{
    background: #4d8381;
  }
  div div.cell-object-status-13{
    background: #7d147d;
  }
  .information-attention{
    color:#900;
    background: #ff9494;
    padding: 2px 4px;
  }
}
input[type="checkbox"] + label.error {
  position: absolute;
  margin-top: 18px;
  margin-left: -13px;
}

.slider-input {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: 40px;
  width:100%;
}
.slider-input .slider.slider-horizontal {
  width: 100% !important;
  height: 20px;
}
.slider-input .slider.slider-horizontal .slider-track {
  height: 10px;
  width: 100%;
  margin-top: -5px;
  top: 50%;
  left: 0;
}
.slider-input .slider.slider-horizontal .slider-selection {
  height: 100%;
  top: 0;
  bottom: 0;
}
.slider-input .slider.slider-horizontal .slider-handle {
  margin-left: -10px;
  margin-top: -5px;
}
.slider-input .slider.slider-horizontal .slider-handle.triangle {
  border-width: 0 10px 10px 10px;
  width: 0;
  height: 0;
  border-bottom-color: #0480be;
  margin-top: 0;
}
.slider-input .slider.slider-vertical {
  height: 210px;
  width: 20px;
}
.slider-input .slider.slider-vertical .slider-track {
  width: 10px;
  height: 100%;
  margin-left: -5px;
  left: 50%;
  top: 0;
}
.slider.slider-vertical .slider-selection {
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
}
.slider-input.slider-vertical .slider-handle {
  margin-left: -5px;
  margin-top: -10px;
}
.slider-input.slider-vertical .slider-handle.triangle {
  border-width: 10px 0 10px 10px;
  width: 1px;
  height: 1px;
  border-left-color: #0480be;
  margin-left: 0;
}
.slider-input input {
  display: none;
}
.slider .tooltip-inner {
  white-space: nowrap;
}
.slider-track {
  width:100%;
  position: absolute;
  cursor: pointer;
  background-color: #f7f7f7;
  background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f9f9f9));
  background-image: -webkit-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -o-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.slider-selection {
  position: absolute;
  background-color: #f7f7f7;
  background-image: -moz-linear-gradient(top, #f9f9f9, #f5f5f5);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f9f9f9), to(#f5f5f5));
  background-image: -webkit-linear-gradient(top, #f9f9f9, #f5f5f5);
  background-image: -o-linear-gradient(top, #f9f9f9, #f5f5f5);
  background-image: linear-gradient(to bottom, #f9f9f9, #f5f5f5);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9', endColorstr='#fff5f5f5', GradientType=0);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.slider-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #0e90d2;
  background-image: -moz-linear-gradient(top, #149bdf, #0480be);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#149bdf), to(#0480be));
  background-image: -webkit-linear-gradient(top, #149bdf, #0480be);
  background-image: -o-linear-gradient(top, #149bdf, #0480be);
  background-image: linear-gradient(to bottom, #149bdf, #0480be);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff149bdf', endColorstr='#ff0480be', GradientType=0);
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  opacity: 0.8;
  border: 0px solid transparent;
}
.slider-handle.round {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.slider-handle.triangle {
  background: transparent none;
}
