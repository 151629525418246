#crisis{
  .inline-block{
    display: inline-block;
  }
  .margin-right{
    margin-right: 8px;
  }
  .pointer{
    cursor: pointer;
  }
  .margin-top{
    margin-top: 25px;
  }
}
