#service-container, #service-dialog {

	#service-message { position: fixed; z-index: 9000000; text-align: center; background: #e44; color: #fff;
		width: 100%; padding: 17px; top: 0; left: 0; display: none; }
	#service-tooltip { display: none; position: absolute; z-index: 9999; background: #000; color: #fff;
		font-size: 11px; padding: 2px 5px; border-radius: 5px; }

	#service-timetable, #stations-timetable {

		tr td { padding: 4px 0px; }
		tr td div.cell { float: left; width: 0.5%; background-color: #f3f3f3; height: 21px; }
		tr td div.cell.free { background-color: #2d6; }
		tr td div.cell.free.hover { background-color: #0a4; cursor: pointer; }
		tr td div.cell.free.blocked { background-color: #ddd; }
		tr td div.cell.free:hover { cursor: pointer; background-color: #0a4; }
		tr td div.cell.repair { background-color: #f33; }
		tr td div.cell.break { background-color: #2d6; }
		tr td div.repair-entry { position: absolute; width: 10%; background-color: #f33; height: 22px; top: 0px;
			border: 1px solid #fff; cursor: pointer; color: #fff; padding: 2px 4px; overflow: hidden; text-align: left; word-break: break-all; }
		tr td div.repair-entry.break { background-color: #0bf; }
		tr td div.repair-resize { position: absolute; height: 22px; top: 0px; cursor: ew-resize; }
		tr td div.break { position: absolute; width: 10%; background-color: #0bf; height: 22px; top: 0px;
			border: 1px solid #fff; color: #fff; padding: 2px 4px; overflow: hidden; text-align: left; word-break: break-all; }

		div.cell-container { position: absolute; width: 100%; height: 23px; top: 0px; border: 1px solid #999; }
		div.cell-container.cell-container-leave { border: 1px solid #a00; background-color: #f33; color: #fff;
			text-align: center; line-height: 20px; font-weight: bold; }

	}

	.helper-timetable tr td { padding: 4px 0px; }
	.helper-timetable tr td div.cell { float: left; width: 0.5%; background-color: none; height: 100%; }
	.helper-timetable tr td div.cell.free { background-color: #2d6; cursor: pointer; }
	.helper-timetable tr td div.cell.free:hover { background-color: #0a4; }
	.helper-timetable tr td div.cell.free.blocked { background-color: #ddd; cursor: not-allowed; }
	.helper-timetable tr td div.cell.repair { background-color: #f12; cursor: not-allowed; }
	.helper-timetable tr td div.cell.repair.repair-order { background-color: #f72; cursor: not-allowed; }
	.helper-timetable tr td div.cell.break { background-color: #0bf; cursor: not-allowed; }
	.helper-timetable tr td div.cell.chosen { background-color: #ff3; }
	.helper-timetable tr td div.cell.blocked.chosen { background-color: #ff3; }
	.helper-timetable div.cell-container { position: absolute; width: 100%; height: 23px; top: 0px; border: 1px solid #999; }
	.helper-timetable div.cell-container.cell-container-leave { border: 1px solid #a00; background-color: #f33; color: #fff;
		text-align: center; line-height: 20px; font-weight: bold; }
	.helper-timetable div.repair-entry { position: absolute; width: 10%; background-color: #f33; height: 22px; top: 0px;
		border: 1px solid #fff; cursor: pointer; color: #fff; padding: 2px 4px; overflow: hidden; text-align: left; word-break: break-all; }

	#service-context-menu {
		position: absolute;
		display: none;
		z-index: 100000;
		border: 1px solid rgba(0, 0, 0, .15);
		border-radius: 4px;
		background: #FFF;
		padding: 0;
		box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
		background-clip: padding-box;
	}
	#service-context-menu ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	#service-context-menu ul li {
		display: block;
		padding: 3px 10px;
		clear: both;
		font-weight: 400;
		line-height: 1.42857;
		color: #333;
		white-space: nowrap;
		font-size: 11px;
		cursor: pointer;
	}
	#service-context-menu ul li ul {
		position: absolute;
		right: -92px;
		display: none;
		background: #FFF;
		margin-top: -19px;
		padding: 0;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		background-clip: padding-box;
		border: 1px solid rgba(0, 0, 0, 0.15);
	}
	#service-context-menu ul li:hover ul { display: block; }
	#service-context-menu ul li:hover { text-decoration: none; color: #262626; background-color: #f5f5f5; }

	.ui-datepicker td.col1 a { background: #1c5; color: #fff; border-color: #0b4; }
	.ui-datepicker td.col2 a { background: #7d3; color: #fff; border-color: #6b2; }
	.ui-datepicker td.col3 a { background: #be3; color: #222; border-color: #ad2; }
	.ui-datepicker td.col4 a { background: #df3; color: #222; border-color: #ce2; }
	.ui-datepicker td.col5 a { background: #ff3; color: #222; border-color: #ee2; }
	.ui-datepicker td.col6 a { background: #fc3; color: #222; border-color: #eb2; }
	.ui-datepicker td.col7 a { background: #fa3; color: #fff; border-color: #e92; }
	.ui-datepicker td.col8 a { background: #f83; color: #fff; border-color: #e72; }
	.ui-datepicker td.col9 a { background: #f53; color: #fff; border-color: #e42; }
	.ui-datepicker td.col10 a { background: #f33; color: #fff; border-color: #e22; }
    .ui-datepicker td.today a { background: #f53; color: #fff; border-color: #e42; }
    .ui-datepicker td.saturday a { background: #ddd; color: #222; border-color: #bbb; }
    .ui-datepicker td.sunday a { background: #aaa; color: #222; border-color: #888; }
    .ui-datepicker td a.ui-state-active { background: #07e; color: #fff; border-color: #07e; }

}
