#common-route-bg-img{
  background: url('/images/pia_route.jpg');
  height: 450px;
  width:100%;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  .nav-button{
    margin: 30px;
  }
}
#common-route{
  .center-input{
    width: 350px;
    select{
      text-align-last: center;
    }
  }
  .form-control{
    height:50px !important;
    text-align: center;
  }
  .btn{
    height: 50px !important;
  }
  #end-point-name, #start-point{
    text-align: center;
  }
  #arrival-point{
    display: none;
  }

  #find-route-title{
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .find-route{
    #find-route{
      width: 160px;
      height: 60px !important;
      font-size: 18px;
    }
    .form-group{
      text-align: center;
    }
    select{
      text-align-last: center;
    }
    label{
      font-weight: bold;
      font-size: larger;
    }
  }

}

#routes{
  .route-list{
    .departure-routes{
      margin-bottom: 30px;
    }
    .arrival-routes{
      margin-bottom: 30px;
      margin-top: 50px;
    }
    #border{
      border: solid 2px;
      border-radius: 10px;
      margin-bottom: 20px;
      #route-not-found{
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
    .panel{
      border-color: #ddd;
      background-color: #a7d1ef;
      .route-list-aligment{
        display: flex;
        align-items: center;
        justify-content: center;
        .departure-time{
          font-size: x-large;
        }
      }
    }
  }
  .create-route{
    display:none;
    .create-route-panel{
      width: 100%;
      border-radius: 10px;
      border: 1px solid #bdcbd6;
      background-color: #bdcbd6;
      #panel-heading-first{
        margin-top: 15px;
        margin-bottom: 15px;
      }
      hr{
        margin-top: 15px;
      }
      #panel-heading-second{
        margin-bottom: 15px;
      }
      .number{
        display: flex;
        align-items: center;
      }
      .form-group{
        .btn-group-lg{
          width: 100%;
          input{
            width: 80%;
          }
          button{
            width: 20%;
          }
        }
        #phone{
          text-align: center;
        }
        #select-dealer{
          text-align-last: center;
        }
        .seats{
          text-align-last: center;
        }
      }
      #route-back{
        display: none;
      }
    }
  }

  .route{
    margin-top:70px;
    float: left;
    width:100%;
    text-align: center;
    .route-heading{
      margin-bottom: 50px;
    }
    .create-route-input{
      width: 80%;
      input{
        width: 100%;
      }
    }
    .create-route-label{
      line-height: 42px;
      vertical-align: middle;
      width:20%;
    }
  }
}

#common-route-user-routes{
  h2{
    margin-bottom: 50px;
  }
  #user-routes{
    margin-bottom: 50px;
  }
}